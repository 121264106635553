import React, { useState, useEffect } from "react";
import Preloader from "./components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Career from "./components/Career/Career";
import Blogs from "./components/Blogs/Blogs";
import Contact from "./components/Contact/Contact";
import Quote from "./components/Quote/Quote";
import Team from "./components/Team/Team";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.scss";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (

    // Temporary Lines Start
    // <div className="text-center text-white" style={{margin:"400px, marginBottom: "400px""}}>
    //   <marquee behavior="" direction="">
    //   <h1 className="orange "> <b>We are Launching Soon :)</b></h1>
    //   </marquee>
    // </div>
    
    // Temporary Lines End


    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/career" element={<Career />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Quote" element={<Quote />} />
          <Route path="/Team" element={<Team />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </div>
    </Router>


  );
}

export default App;
